import { FluidMaterialModel } from './fluid-material.model';
import { SlurrySource } from 'libs/constants';
import { IFactsRequestInfo } from 'libs/models';
import { Guid } from 'apps/vida/src/modules/shared/constant';
import { Rheology } from './rheology.model';

export class FluidModel {
  id: string = null;
  jobId: string = Guid.Empty;
  name: string = null;
  testType: string = null;
  slurryType: string = null;
  slurryNo: number = null;
  slurryId: number = null;
  slurryIdHDF: string = null;
  hdfSlurryIds: string[] = [];
  primaryStatus: string = null;
  primaryStatusId: string = null;
  opsMessage: number = 0;
  status: string = null;
  displayName: string = null;
  iCemName: string = null;
  tempId?: string = null;
  requestId?: number = null;
  requestIdHDF: string = null;
  requestDisplay: string = null;
  testTypeId: string = null;
  slurryTypeId: string = null;
  testStatus: string = null;
  density: number = null;
  water: string = null;
  waterSAPNumber: string = null;
  mixFluid: number = null;
  waterRequirements: number = null;
  mixWater: number = null;
  yield: number = null;
  mixWaterId: string = null;
  mixVolume: number = null;
  sapMaterialNumber: string = null;
  sapMaterialName: string = null;
  isFoam: boolean = false;
  foamDensity: number = null;
  foamQuality: number = null;
  fluidTestStatusId: number = null;
  order: number = null;
  isCement: boolean = false;
  fluidMaterial: FluidMaterialModel[] = [];
  supplementalMaterial: FluidMaterialModel[] = [];
  slurrySource: SlurrySource = SlurrySource.ManualFluid;
  isCementBlend: boolean = false;
  labName: string = null;
  blendName: string = null;
  cementName: string = null;
  sackWeight: number = null;
  requestInfo: IFactsRequestInfo = null;
  isHdfStage: boolean = false;
  cemmentBulkDensity: number = null;
  bhst: number = null;
  bhct: number = null;
  thickeningTime: any = null;
  materialType: string = null;
  waterDensity: number = null;
  isFromVisualizer: boolean = false;
  isFromIFactsBasicSearch: boolean = false;
  pumpScheduleNumber: number = null;
  createdDate: string = null;
  modifiedDate: string = null;
  totalBlendCo2e: string = null;

  stageNumber: number = null
  /**
   *
   */

  // for FE only - refactor later
  hasPendingMessage: boolean;
  iconStatus: string;

  // This is a temporary var for thickening time
  // so that we can show it on UI with corresponding Job Type in New Job
  originThickeningTime: any;

  rheologies: Rheology[]

  private static valueEquals(a: any, b: any) {
    if (!a && !b) {
      return true;
    }

    return a == b;
  }

  public static equals(a: FluidModel, other: FluidModel): boolean {
    if (!FluidModel.valueEquals(a.id, other.id)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.jobId, other.jobId)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.name, other.name)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.testType, other.testType)) {
      return false;
    }

    if (a.slurryNo != other.slurryNo) {
      return false;
    }

    if (!FluidModel.valueEquals(a.slurryId, other.slurryId)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.slurryIdHDF, other.slurryIdHDF)) {
      return false;
    }

    if (a.hdfSlurryIds != other.hdfSlurryIds) {
      return false;
    }

    if (!FluidModel.valueEquals(a.primaryStatus, other.primaryStatus)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.primaryStatusId, other.primaryStatusId)) {
      return false;
    }

    if (a.opsMessage != other.opsMessage) {
      return false;
    }

    if (!FluidModel.valueEquals(a.status, other.status)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.displayName, other.displayName)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.iCemName, other.iCemName)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.tempId, other.tempId)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.requestId, other.requestId)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.requestIdHDF, other.requestIdHDF)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.requestDisplay, other.requestDisplay)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.testTypeId, other.testTypeId)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.slurryTypeId, other.slurryTypeId)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.testStatus, other.testStatus)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.density, other.density)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.water, other.water)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.waterSAPNumber, other.waterSAPNumber)) {
      return false;
    }

    if (a.mixFluid != other.mixFluid) {
      return false;
    }

    if (a.waterRequirements != other.waterRequirements) {
      return false;
    }

    if (a.mixWater != other.mixWater) {
      return false;
    }

    if (a.yield != other.yield) {
      return false;
    }

    if (!FluidModel.valueEquals(a.mixWaterId, other.mixWaterId)) {
      return false;
    }

    if (a.mixVolume != other.mixVolume) {
      return false;
    }

    if (!FluidModel.valueEquals(a.sapMaterialNumber, other.sapMaterialNumber)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.sapMaterialName, other.sapMaterialName)) {
      return false;
    }

    if (a.isFoam != other.isFoam) {
      return false;
    }

    if (a.foamDensity != other.foamDensity) {
      return false;
    }

    if (a.foamQuality != other.foamQuality) {
      return false;
    }

    if (a.fluidTestStatusId != other.fluidTestStatusId) {
      return false;
    }

    if (a.order != other.order) {
      return false;
    }

    if (a.isCement != other.isCement) {
      return false;
    }

    if (a.fluidMaterial != other.fluidMaterial) {
      return false;
    }

    if (a.supplementalMaterial != other.supplementalMaterial) {
      return false;
    }

    if (a.slurrySource != other.slurrySource) {
      return false;
    }

    if (a.isCementBlend != other.isCementBlend) {
      return false;
    }

    if (!FluidModel.valueEquals(a.labName, other.labName)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.blendName, other.blendName)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.cementName, other.cementName)) {
      return false;
    }

    if (a.sackWeight != other.sackWeight) {
      return false;
    }

    if (!FluidModel.valueEquals(a.requestInfo, other.requestInfo)) {
      return false;
    }

    if (a.isHdfStage != other.isHdfStage) {
      return false;
    }

    if (a.cemmentBulkDensity != other.cemmentBulkDensity) {
      return false;
    }

    if (a.bhst != other.bhst) {
      return false;
    }

    if (a.bhct != other.bhct) {
      return false;
    }

    if (a.thickeningTime != other.thickeningTime) {
      return false;
    }

    if (!FluidModel.valueEquals(a.materialType, other.materialType)) {
      return false;
    }

    if (a.waterDensity != other.waterDensity) {
      return false;
    }

    if (a.isFromVisualizer != other.thickeningTime) {
      return false;
    }

    if (a.isFromIFactsBasicSearch != other.isFromIFactsBasicSearch) {
      return false;
    }

    if (a.pumpScheduleNumber != other.pumpScheduleNumber) {
      return false;
    }

    if (!FluidModel.valueEquals(a.createdDate, other.createdDate)) {
      return false;
    }

    if (!FluidModel.valueEquals(a.modifiedDate, other.modifiedDate)) {
      return false;
    }

    if (a.hasPendingMessage != other.hasPendingMessage) {
      return false;
    }

    if (!FluidModel.valueEquals(a.iconStatus, other.iconStatus)) {
      return false;
    }

    if (a.originThickeningTime != other.originThickeningTime) {
      return false;
    }

    if (a.rheologies != other.rheologies) {
      return false;
    }

    return true;
  }
}
