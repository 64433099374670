import { Component, Input, SimpleChanges, OnInit, OnChanges, OnDestroy, ViewChildren, QueryList, Injector } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { Job, FluidModel, ISlurryType, ITestType, IMaterialTypeModel, FluidMaterialModel, PumpSchedule } from 'libs/models';
import { Subscription, Subject, forkJoin, Observable, concat, combineLatest, BehaviorSubject } from 'rxjs';
import { IfactFluidSettingsSidebarComponent } from '../../../sidebar-dialogs/components';
import { MessageService } from 'primeng/api';
import { CommonMessageText, ActionState, JobActionMode } from '../../../shared/constant';
import { DynamicSidebarRef, DynamicSidebarService, PDropDownModel } from 'libs/ui';
import { filter, finalize, map, shareReplay, take, takeUntil, tap } from 'rxjs/operators';
import { FluidDetailComponent } from '../fluid-detail/fluid-detail.component';
import { FormControlContainer } from 'libs/ui';
import { SlurrySource, UnitType, IFACT_CONSTANTS } from 'libs/constants';
import { IfactCopyRequestSidebarComponent } from '../../../sidebar-dialogs/components';
import { UnitConversionService } from 'libs/ui/unit-conversions';
import { MasterDataService, FluidService, SessionService, JobStateService, ApplicationStateService, IfactService } from 'libs/shared/services';
import { isFormInvalid } from 'libs/helpers';
import { FluidFormFactory } from './fluid-form.factory';
import { uniqBy } from 'libs/helpers/lodash-helper';
import { EditJobAdapter } from '../../../edit-job/adapters';
import { PDropdownDataSanitizerHelper } from '../../../shared/helpers';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MODAL_ID } from 'libs/constants/modal-id.constants';
import { LazyLoadingService } from 'libs/shared/services/lazy-loading.service';
import { environment } from 'libs/environment';
import { FluidsAdapter } from '../../adapters';
import { PumpScheduleStateFactory } from '../../../pump/state/state-factory';
import { PumpScheduleStageStateManager } from '../../../pump/models/pump-schedule-stage-state-manager.model';
import {IfactSearchSidebarComponent} from 'apps/vida/src/modules/ifacts-search/components';
import { ControlPointAdapter } from '../../../control-point/adapters';

@Component({
  selector: 'fluid-container',
  templateUrl: './fluid-container.component.html',
  styleUrls: ['./fluid-container.component.scss'],
  providers: [
    FluidsAdapter
  ]
})

export class FluidContainerComponent extends FormControlContainer implements OnInit, OnDestroy, OnChanges {
  @Input() model: FluidModel[];
  @Input() job: Job;
  @Input() currentPumpScheduleTabIndex: number;

  @ViewChildren('fluidDetail') fluidDetails: QueryList<FluidDetailComponent>;
  subscriptions: Subscription[] = [];
  ifactFluidSettingsSidebarRef: DynamicSidebarRef<IfactFluidSettingsSidebarComponent>;
  slurryTypes: ISlurryType[];
  testTypes: ITestType[];
  loadingSubscription: Subscription;
  materialTypes: IMaterialTypeModel[];
  ifactCopyRequestSidebarRef: DynamicSidebarRef<IfactCopyRequestSidebarComponent>;
  ifactCopyRequestSubscription: Subscription;
  jobStateCancel: boolean = false;
  createIFactRequestSubscription: Subscription;
  concentrationUnitList: any[] = [];
  pumps: PumpScheduleStageStateManager[] = [];
  fluidModels : FluidModel[] = [];
  pumpLoaded = false;
  model$ = new BehaviorSubject(null);
  private readonly destroy$ = new Subject();
  public isAddPumpScheduleVisible$ = concat(this.appStateService.jobParams$.pipe(
    map(c => c.isStageJob),
    shareReplay()
  ));
  constructor(
    protected inj: Injector,
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private dynamicSidebarService: DynamicSidebarService,
    private masterDataService: MasterDataService,
    private unitConversionService: UnitConversionService,
    private fluidService: FluidService,
    private sessionService: SessionService,
    private jobStateService: JobStateService,
    public editJobAdapter: EditJobAdapter,
    public fluidsAdapter: FluidsAdapter,
    public appStateService: ApplicationStateService,
    private ifactService: IfactService,
    private readonly _fluidFormFactory: FluidFormFactory,
    private bsModalService: BsModalService,
    private lazyLoadingService: LazyLoadingService,
    private readonly _scheduleStateFactory: PumpScheduleStateFactory,
    private controlPointAdapter: ControlPointAdapter,
  ) {
    super(inj);
  }

  ngOnInit() {
    this.isAddPumpScheduleVisible$.subscribe((visible)=>{
      this.job.isStageJob = visible
    });



    forkJoin([this.initMasterData(), this.editJobAdapter.getAvailableSapMappings$(this.job.group)])
      .subscribe(([_, materialMappings]) => {
        this.initForm(this.model);
      if (!this.job.editMode && !this.job.isClonedJob && this.model) {
        this.model.forEach(fm => this.editJobAdapter.updateFluidMaterialsSAPNumber(fm, materialMappings));
      }

      this.createIFactRequestSubscription = this.appStateService.createIFactsRequestCompleted$.subscribe(res => {
        if (res) {
          const { index, result: { requestid, slurryid } } = res;
          const frmFluid: UntypedFormGroup = this.editJobAdapter.fluidFormArray.controls[index] as UntypedFormGroup;
          const fluid = frmFluid.getRawValue();
          fluid.requestId = requestid;
          fluid.slurryNo = 1;
          fluid.slurryId = slurryid;
          fluid.slurrySource = SlurrySource.LinkedFluid;

          this.ifactService.moveSuplementalToAdditives(fluid);

          this._loadDataAndReplaceFluid(index, fluid);
        }
      });
    });

    if (!environment.usePumpSchedule_1_3) {
      this.subscriptions.push(
        this.editJobAdapter.pumpScheduleModel$.subscribe(pumpScheduleModel => {
          this._readFluids(pumpScheduleModel[this.currentPumpScheduleTabIndex], pumpScheduleModel);
        })
      );
    }

    if (environment.usePumpSchedule_1_3) {
      this.subscriptions.push(
        combineLatest([
          this._scheduleStateFactory.pumpSchedule$,
          this._scheduleStateFactory.pumpSchedules$,
          this.model$
        ]).subscribe(([ps, allPS, model]) => {
          this._readFluids(ps, allPS);
        })
      );
    }
  }


  public get listPumpScheduleStageStateManager$(): Observable<PumpScheduleStageStateManager[]> {
    return this._scheduleStateFactory.listPumpScheduleStageStateManager$
  }

  initMasterData(): Observable<any> {
    this.editJobAdapter.initMasterData();
    return forkJoin(
      this.masterDataService.listTestTypes(),
      this.masterDataService.listSlurryTypes(),
      this.masterDataService.listMaterialTypes(),
      this.masterDataService.listFluidUnitMeasures()?.pipe(
        map(uomList => PDropdownDataSanitizerHelper('name', 'id', uomList)),
        map(items => {
          const unitsOfMeasure = items.filter(
            uom => uom.label.toLowerCase().replace(/\s+/g, '') in IFACT_CONSTANTS.UNITS_OF_MEASURE
          );
          return unitsOfMeasure;
        }),
      )
    ).pipe(tap(([testTypes, slurryTypes, materialTypes, data]) => {
      this.testTypes = testTypes;
      this.slurryTypes = slurryTypes;
      this.materialTypes = materialTypes;
      this.concentrationUnitList = data.sort((x, y) => x.label.toLowerCase().localeCompare(y.label.toLowerCase()));
    }));
  }

  ngOnDestroy() {
    if (this.createIFactRequestSubscription) {
      this.createIFactRequestSubscription.unsubscribe();
      this.createIFactRequestSubscription = null;
    }
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];

    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private _readFluids(pumpScheduleModel: PumpSchedule, allPumpSchedule: PumpSchedule[]): void {
    // assume fluids are in job.slurry array
    let fluids = this.model ? this.model : [];

    if (pumpScheduleModel && pumpScheduleModel.isImportDataFromiCem) {

      this.job.pumpSchedules.forEach(pumpSchedule => {
        if (pumpSchedule.stages) {
          pumpSchedule.stages.filter(stage => stage && stage.slurry)
            .forEach(stage => fluids.push(stage.slurry));
        }
      })

      // select unique fluids only from pump schedule stages
      fluids = uniqBy(fluids, f => f.requestId && f.slurryNo ? [f.requestId, f.slurryNo].join() : f.displayName);
    }

    if (pumpScheduleModel && pumpScheduleModel.isImportDataFromHDF) {

      // if job is imported from HDF then take fluids from job.slurry again
      fluids = this.model;
    }

    if (fluids.length === 0) {
      if (this.model != null) {
         this.editJobAdapter.isFluidReady$.next(true);
      }
    }

    this.loadingSubscription = this.editJobAdapter.loadingMoreDataForFluids(this.job, fluids)
      .pipe(
        take(1),
        finalize(() => this.fluidService.publishYieldChangedWarningMessages())
      )
      .subscribe(fluids => {
        this.initForm(fluids);

        if (fluids?.length && !this.job.isImportDataFromiCem) {
          const psManagers = this.controlPointAdapter.mapFluidDataToStage(allPumpSchedule, fluids)
            .map((ps, index) => this._scheduleStateFactory.createPumpScheduleStageStateManager(ps, index, this.job, null));
          this._scheduleStateFactory.updateListPumpScheduleStageStateManager(psManagers);
        }

        if (this.model != null) {
          this.editJobAdapter.isFluidReady$.next(true);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const { model } = changes;

    if ((model && model.currentValue && !this.appStateService.notifyIFactDown$.value) || model && model.isFirstChange()) {

      this.editJobAdapter.isLoadingNewJob = true;
      this.model$.next(model.currentValue);
    }

    // Check disable all field when job is Cancel
    this.jobStateCancel = (this.job ? this.job.jobStatus === this.jobStateService.findJobState('Cancelled') : false) ||
      (this.job ? this.job.jobStatus === this.jobStateService.findJobState('Closed') : false);
  }

  initForm(fluids: FluidModel[]) {
    var newFluids : FluidModel[] = [];
    if(fluids)
    {
      fluids.forEach(element => {
        if(!newFluids.some(x => (x.id && element.id) && (x.id == element.id)))
        {
          newFluids.push(element);
        }
      });
    }

    if (newFluids && this.testTypes) {
      newFluids = newFluids.filter(s => s.slurrySource === SlurrySource.LinkedFluid || s.slurrySource === SlurrySource.ManualFluid || s.slurrySource === SlurrySource.HDFFluid);
      newFluids.forEach(fluid => {
        if (!fluid.testTypeId && fluid.testType) {
          const testType = this.testTypes.find(x => x.name === fluid.testType);
          fluid.testTypeId = testType ? testType.id : '';
        }
      });
    }

    // This is to reset the fluid form data
    // Put _insertFluids method after this chunk of code
    const fluidForm = this.formControl = this.fb.group({
      slurry: this.fb.array([])
    });
    this.editJobAdapter.fluidForm$.next(fluidForm);
    if (newFluids) {
      this._insertFluids(false, ...newFluids);
    }
  }

  addNewFluid() {
    const fluid = new FluidModel();
    if (this.testTypes) {
      fluid.testTypeId = this.testTypes.find(x => x.name === 'Manual') ? this.testTypes.find(x => x.name === 'Manual').id : '';
    }
    fluid.slurrySource = SlurrySource.ManualFluid;
    this.loadingSubscription = this.editJobAdapter.loadDataAndInsertFluids(this.job, null,fluid)
      .subscribe();
  }

  private _insertFluids(isCheckDirty: boolean, ...fluids: FluidModel[]) {
    this.editJobAdapter.insertFluids(this.job, ...fluids);
    if (fluids && fluids.length && isCheckDirty)
      this.markAsDirty();
  }

  public setFluidMaterialIds(fluids: FluidModel[]) {
    this.editJobAdapter.fluidFormArray.controls.forEach((fl: UntypedFormArray) => {
      const fluidId = fl.get('id').value;
      const fluidModel = fluids.find(x => x.id === fluidId);
      const fluidMaterialForm = fl.get('fluidMaterial') as UntypedFormArray;
      fluidMaterialForm.controls.forEach((x: UntypedFormGroup) => {
        const material = x.getRawValue();
        if (!material.id) {
          const model = fluidModel.fluidMaterial.find(x => x.materialId === material.materialId && x.concentration === material.concentration);
          if (model) {
            x.controls.id.setValue(model.id);
            fluidModel.fluidMaterial = fluidModel.fluidMaterial.filter(x => x !== model);
          }
        }
      });
    });
  }

  private _loadDataAndReplaceFluid(index: number, fluid: FluidModel) {
    this.loadingSubscription = this.fluidService.loadFromIFacts(this.job, fluid).pipe(
      map(fluids => fluids[0]),
      map((fluid: FluidModel) => {
        const oldFluid = this.editJobAdapter.fluidFormArray.getRawValue()[index];
        const oldFluidMaterial = oldFluid.fluidMaterial;
        // move suplemental materials and reorder them according ifacts materials order
        this.ifactService.moveSuplementalToAdditives(oldFluid);
        this.ifactService.reorderFluidMaterialsCollection(oldFluid.fluidMaterial);

        fluid.fluidMaterial.forEach((fm: FluidMaterialModel, idx) => {
          if (oldFluidMaterial[idx])
            fm.sapMaterialNumber = oldFluidMaterial[idx].sapMaterialNumber;
        });
        return fluid;
      })
    ).subscribe(fluidModel => {
      this._replaceFluid(index, fluidModel);
      this.editJobAdapter.updateFluids$();
    });
  }

  private _replaceFluid(index: number, fluid: FluidModel) {
    this.editJobAdapter.fluidFormArray.controls[index] = this._fluidFormFactory.createFluidForm(fluid);
    this.markAsDirty();
  }

  deleteFluid(index) {

    const fluidControls = this.editJobAdapter.fluidFormArray;
    fluidControls.removeAt(index);
    this.editJobAdapter.updateFluids$();
  }

  showIfactSearchPage() {
    this.lazyLoadingService.loadIFactsSearchModule().then(m => {
      const modalRef = this.bsModalService.show<IfactSearchSidebarComponent>(m.components.IFactsSearchComponent,
        {
          initialState: {
            data: {
              jobId: this.job.id,
              jobStatus: this.job.jobStatusDescription,
              saveRequestFn: (p) => this.addSelectedRequests(p),
              prefUserName: this.sessionService.user.prefUserName,
              autoZIndex: true,
              groupId: this.job.group,
              groupName: `${this.job.regionCode} - ${this.job.groupName}`,
              wasCalledFromJob: true
            }
          },
          class: 'full-screen-modal ifact-full-screen-modal',
          ignoreBackdropClick: true,
          keyboard: false,
          id: MODAL_ID.IFACTS_BASIC_SEARCH
        });
      modalRef.content.onClose.subscribe((selectedRequests: FluidModel[]) => {
        if (selectedRequests) {
          selectedRequests.forEach(fi => fi.isFromIFactsBasicSearch = true);
          this.addSelectedRequests(selectedRequests);
        }
      });
    });
  }

  showFluidSettings() {
    this.ifactFluidSettingsSidebarRef = this.dynamicSidebarService.open(IfactFluidSettingsSidebarComponent, {
      data: {
        job: this.job,
        jobId: this.job.id,
        isLinkFluidRequest: true,
      },

    });
    this.ifactFluidSettingsSidebarRef.onClose.pipe(filter(data => !!data)).subscribe((selectedRequests: any[]) => {
      if (selectedRequests && selectedRequests.length) {
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: CommonMessageText.FLUID_ADD.SUCCESS });
        this.addSelectedRequests(selectedRequests);
      }
    });
  }

  copyRequest(index: number) {
    const frmFluid: UntypedFormGroup = this.editJobAdapter.fluidFormArray.controls[index] as UntypedFormGroup;
    let fluidModel: FluidModel = frmFluid.getRawValue();
    const slurryType = this.editJobAdapter.slurryTypeData$.value.find(x => x.value === fluidModel.slurryTypeId);

    [fluidModel] = this.assignOrderForSupplementalMaterials([fluidModel]);
    fluidModel.fluidMaterial = fluidModel.fluidMaterial.map(material => {
      material.id = null;
      return material;
    });
    if (fluidModel.slurrySource === 1) {
      this.loadingSubscription =
        this.editJobAdapter.loadDataAndInsertFluids(this.job, null,fluidModel).subscribe();
    }
    if (fluidModel.slurrySource === 2) {
      this.showCopyRequest(fluidModel, slurryType);
    }
  }

  createRequest(index: number) {
    const frmFluid: UntypedFormGroup = this.editJobAdapter.fluidFormArray.controls[index] as UntypedFormGroup;
    const density = this.unitConversionService.convertFromApiToSi(frmFluid.controls.density.value, UnitType.Density);
    let fluidModel: FluidModel = frmFluid.getRawValue();
    const slurryType = this.editJobAdapter.slurryTypeData$.value.find(x => x.value === fluidModel.slurryTypeId);

    [fluidModel] = this.assignOrderForSupplementalMaterials([fluidModel]);
    fluidModel.fluidMaterial = fluidModel.fluidMaterial.map(material => {
      material.id = null;
      return material;
    });

    this.showCreateRequest(index, fluidModel, density, fluidModel.id, frmFluid, slurryType);
  }

  showCreateRequest(index: number, fluidModel: FluidModel, density: number, fluidId: string, frmFluid: UntypedFormGroup, slurryType: PDropDownModel) {
    const testType = this.testTypes.find(x => x.id === fluidModel.testTypeId);
    this.ifactCopyRequestSidebarRef = this.dynamicSidebarService.open(IfactCopyRequestSidebarComponent, {
      data: {
        index: index,
        job: this.job,
        prefUserName: this.sessionService.user.prefUserName,
        requestId: fluidModel.requestId,
        slurryNo: fluidModel.slurryNo,
        slurryId: fluidModel.slurryId,
        testType: testType.name,
        density,
        fluidId,
        fluid: fluidModel,
        slurryTypeLabel: slurryType != null ? slurryType.label : '',
        action: ActionState.Created
      }
    });

    if (this.ifactCopyRequestSubscription)
      this.ifactCopyRequestSubscription.unsubscribe();

    this.ifactCopyRequestSubscription = this.ifactCopyRequestSidebarRef.onClose.subscribe(fluid => {
      if (fluid) {
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: CommonMessageText.FLUID_ADD.SUCCESS });
      }
    });
  }

  showCopyRequest(fluidModel: FluidModel, slurryType: PDropDownModel) {
    const testType = this.testTypes.find(x => x.id === fluidModel.testTypeId);
    this.ifactCopyRequestSidebarRef = this.dynamicSidebarService.open(IfactCopyRequestSidebarComponent, {
      data: {
        job: this.job,
        prefUserName: this.sessionService.user.prefUserName,
        fluid: fluidModel,
        requestId: fluidModel.requestId,
        slurryNo: fluidModel.slurryNo,
        slurryId: fluidModel.slurryId,
        testType: testType.name,
        fluidId: fluidModel.id,
        slurryTypeLabel: slurryType != null ? slurryType.label : '',
        action: ActionState.Copy
      }
    });
    if (this.ifactCopyRequestSubscription)
      this.ifactCopyRequestSubscription.unsubscribe();

    this.ifactCopyRequestSubscription = this.ifactCopyRequestSidebarRef.onClose.subscribe(fluid => {
      if (fluid) {
        this.loadingSubscription =
          this.editJobAdapter.loadDataAndInsertFluids(this.job, fluid).subscribe();
      }
    });

  }

  addSelectedRequests(fluids: FluidModel[]): Subscription {
    this.loadingSubscription =
      this.editJobAdapter.loadDataAndInsertFluids(this.job, null, ...fluids).subscribe(
        (addedFluids: FluidModel[]) => {
          if (addedFluids?.length) {
            this.markAsDirty();
          }
        });
    return this.loadingSubscription;
  }

  getSanitizedData(clearIds: boolean = true): any[] {
    let fluids: FluidModel[] = this.editJobAdapter.fluidFormArray.getRawValue();
    fluids = fluids && fluids.length ? this.assignOrderForSupplementalMaterials(fluids) : [];
    fluids = this.assignRheologies(fluids);

    if (this.job.isClonedJob) {

      return fluids.map(fluid => ({
        ...fluid,
        id: null,
        fluidMaterial: fluid.fluidMaterial.map(material => ({
          ...material,
          id: null,
          slurryId: null
        })
        )
      })
      );
    }

    if (!clearIds) {
      return fluids;
    }

    return fluids.map(fluid => ({
      ...fluid,
      fluidMaterial: fluid.fluidMaterial.map(material => ({
        ...material
      }))
    }));
  }

  isTouched(): boolean {
    return this.fluidDetails && this.fluidDetails.toArray().every(f => f.isTouched());
  }

  markAsTouched() {
    super.markAsTouched();
    this.fluidDetails.forEach(f => f.markAsTouched());
  }

  isInvalid(): boolean {
    return isFormInvalid(this.editJobAdapter.fluidForm$.value);
  }

  assignOrderForSupplementalMaterials(fluids: FluidModel[]) {
    return fluids.map(fluid => {
      let maxOrder = fluid.fluidMaterial.filter(fm => fm.materialType !== 'Supplemental' && fm.order && fm.order !== 100).map(fm => fm.order).max();

      fluid.fluidMaterial.filter(fm => fm.materialType === 'Supplemental').forEach(fm => {
        fm.order = ++maxOrder;
      });

      return fluid;
    });
  }

  assignRheologies(fluids: FluidModel[]) {
    return fluids?.map((fluid, index) => {
      if (this.model && this.model[index]) {
        fluid.rheologies = this.model[index].rheologies
      }

      return fluid;
    })
  }
}
